<template>
  <BaseTableWrapper
    :columns="columns"
    :table-init="tableInit"
    :initial-filters="initialFilters"
  >
    <h1 class="page-header">Known Addresses</h1>
  </BaseTableWrapper>
</template>

<script>
import knownAddress from '@/services/knownAddress'
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import { DateTime } from 'luxon'
import BaseActionsRequiresWrapper from '@/components/BaseActionsRequiresWrapper.vue'
import actionsWrapper from '@/utils/BaseActionsWrapper'
import { deepClone } from '@/utils/deepClone'
import { datePredefined } from '@/utils/predefined'

const wrappedBaseActions = actionsWrapper(
  BaseActionsRequiresWrapper,
  'knownAddress',
  ['view-known-address', 'edit-known-address'],
  'knownAddresses'
)

const columns = [
  {
    _t_id: 'ee2b5823-652f-48e2-b049-80e18ff9d958',
    prop: '/',
    text: 'Actions',
    component: wrappedBaseActions,
    sort: false,
    filter: false,
    detail: false,
    type: 'actions',
  },
  {
    _t_id: '99d95273-7eb7-4168-9ffd-e9834bd9eaad',
    prop: 'knownAddressId',
    text: 'ID',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    sortProp: 'knownAddressId',
    defaultSort: true,
  },
  {
    _t_id: 'f38bf413-8c77-444f-bc84-510653d1b66d',
    prop: 'title',
    text: 'Title',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    sortProp: 'title',
    defaultSort: false,
  },
  {
    _t_id: 'cb6fc6e1-41f1-4b04-a07e-18f763b7c8ae',
    prop: 'street1',
    text: 'Street 1',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    sortProp: 'title',
    defaultSort: false,
    defaultHidden: true,
  },
  {
    _t_id: '3df160e8-f1a4-4aa1-bb20-0a077702d0bd',
    prop: 'street2',
    text: 'Street 2',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'eq',
    sortProp: 'title',
    defaultSort: false,
    defaultHidden: true,
  },
  {
    _t_id: '7650a6a6-1707-440b-b667-1662b3289742',
    prop: 'city',
    text: 'City',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    sortProp: 'city',
    defaultSort: false,
  },
  {
    _t_id: '62aaae81-be82-46d5-a6d4-24e3d6a5662d',
    prop: 'state',
    text: 'State',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    sortProp: 'state',
    defaultSort: false,
  },
  {
    _t_id: '038b81f4-c04a-44f4-a4ba-0b01fca978bb',
    prop: 'lat',
    text: 'Latitude',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    sortProp: 'lat',
    defaultSort: false,
  },
  {
    _t_id: 'e63c3d52-b06d-4737-9060-c0f6b8f964f0',
    prop: 'lng',
    text: 'Longitude',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    sortProp: 'lng',
    defaultSort: false,
  },
  {
    _t_id: '2b94de4a-87a9-482a-8c63-8518508b647f',
    prop: 'updatedOn',
    text: 'Date Updated',
    sort: true,
    filter: true,
    type: 'date',
    filterType: 'eq',
    sortProp: 'updatedOn',
    defaultHidden: true,
    computedText: (item) =>
      item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
    predefined: deepClone(datePredefined),
    detail: false,
  },
  {
    _t_id: '47abc5f9-ebc3-4fff-95f4-fd1f406b043f',
    prop: 'createdOn',
    text: 'Date Added',
    sort: true,
    filter: true,
    type: 'date',
    filterType: 'eq',
    sortProp: 'createdOn',
    defaultHidden: true,
    computedText: (item) =>
      item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
    predefined: deepClone(datePredefined),
    detail: false,
  },
]

export default {
  components: {
    BaseTableWrapper,
  },
  metaInfo() {
    return {
      title: 'Known Addresses',
    }
  },
  data() {
    return {
      isAdmin: false,
      tableInit: {
        detailKeyId: 'knownAddressId',
        tableId: 'known_address_tv_view',
        enableStatusFilterToggle: false,
        enableColumnConfig: true,
        enableSavedViews: true,
        addNewEnabled: true,
        addNewHandler: () => this.$router.push({ name: 'known-address.add' }),
        currentPage: 1,
        perPage: 10,
        isDetailed: false,
        columns,
        collection: 'Known Addresses',
        action: (params) => knownAddress.tableView(params),
      },
      initialFilters: [],
    }
  },
  async mounted() {
    this.initialFilters = [
      {
        column: {
          _t_id: 'e7892e35-bac8-49b9-badf-a1cfcd62a3dc',
          prop: 'parentKnownAddressId',
          filterType: 'isnull',
          type: 'number',
        },
        value: 1,
        hideOnFilterBar: true,
      },
    ]
  },
  methods: {},
}
</script>

<style lang="scss" scoped></style>
