import { render, staticRenderFns } from "./KnownAddressList.vue?vue&type=template&id=de94fb82&scoped=true"
import script from "./KnownAddressList.vue?vue&type=script&lang=js"
export * from "./KnownAddressList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de94fb82",
  null
  
)

export default component.exports